<template>
  <div class="full-container">
    <title-header :title="'平板锁ID设置'" />
    <div class="setting-container">
      <div class="flex nowrap">
        <md-field>
          <md-field-item
            title="服务："
            :content="selectorValue"
            @click="showSelector"
            arrow
            solid
          />
        </md-field>
        <md-selector
          v-model="isSelectorShow"
          default-value="1"
          :data="serviceData"
          max-height="220px"
          title="请选择服务"
          large-radius
          @choose="onSelectorChoose"
        ></md-selector>
      </div>
      <div class="flex nowrap setting-item">
        <p class="setting-tips">锁号：</p>
        <div class="flex-no-wrap fields-item">
          <input
            type="number"
            class="fields-input"
            v-model="lockNo"
            @blur="checkLength('lockNo')"
          />
          <md-icon
            name="scan"
            size="lg"
            @click="controlScanModal(true, 'er')"
          ></md-icon>
        </div>
        <scan
          v-if="isErScan"
          :code-type="'er'"
          @closeScan="controlScanModal(false, 'er')"
          @getCode="getScanLockCode"
        />
      </div>
      <div class="flex nowrap setting-item">
        <p class="setting-tips">IMEI：</p>
        <div class="flex-no-wrap fields-item">
          <input
            type="number"
            class="fields-input"
            v-model="imei"
            @blur="checkLength('iemi')"
          />
          <md-icon
            name="scan"
            size="lg"
            @click="controlScanModal(true, 'bar')"
          ></md-icon>
        </div>
        <scan
          v-if="isBarScan"
          :code-type="'bar'"
          @closeScan="controlScanModal(false, 'bar')"
          @getCode="getScanIMEICode"
        />
      </div>

      <!-- 添加设置 -->
      <md-button
        type="primary"
        size="small"
        class="add-btn"
        round
        @click="addSetting"
        >添加设置</md-button
      >

      <div class="flex filter-box" v-if="isProgress">
        <a href="javascript:void(0)" class="filter-btn" @click="clearSuccess">清空成功项</a>
        <a href="javascript:void(0)" class="filter-btn" @click="confirmClearAll">清空结果</a>
      </div>
      <!-- 添加列表 -->
      <table cellspacing="0" cellpadding="0" class="test-table">
        <tr class="tr-header">
          <th>序号</th>
          <th>锁号</th>
          <th>IMEI</th>
          <th>{{ isProgress ? '状态' : '操作' }}</th>
        </tr>
        <tr class="tr-body" v-for="(item, index) in dealArray" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item.lockNo }}</td>
          <td>{{ item.imei }}</td>
          <td>
            <div
              class="remove-line"
              @click="confirmRemove(index)"
              v-if="!isProgress"
            >
              删除
            </div>
            <div class="status-box" v-else>
              <!-- 显示设置状态 -->
              <md-icon
                v-if="item.status === 0"
                name="spinner"
                size="small"
                style="-webkit-filter: invert(1)"
              ></md-icon>
              <md-icon name="success-color" size="small" svg v-if="item.status === 1 || item.status === 3"></md-icon>
              <md-icon name="warn-color" size="small" svg v-if="item.status === 2"></md-icon>
              <md-icon name="question" size="lg" v-if="item.status === 3"></md-icon>
            </div>
          </td>
        </tr>
        <tr v-if="dealArray.length === 0">
          <td style="text-align: center" colspan="4">请添加配置信息</td>
        </tr>
      </table>

      <!-- 操作区域 -->
      <div class="opt-box">
        <md-button
          type="primary"
          size="small"
          round
          inline
          class="opt-btn"
          @click="confirmBegin"
          >开始</md-button
        >
        <md-button
          type="warning"
          size="small"
          round
          inline
          class="opt-btn"
          @click="confirmFinish"
          >结束</md-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import TitleHeader from "../../components/TitleHeader";
import Scan from "../../components/Scan";
import { Dialog, Toast } from "mand-mobile";
import apiConfig from "../../assets/config/api.config";
import axios from "axios";

const formDataHeader = {'Content-Type': 'application/x-www-form-urlencoded'};
export default {
  name: "setting",
  components: {
    TitleHeader,
    Scan,
  },
  data() {
    return {
      isErScan: false,
      isBarScan: false,
      lockNo: "",
      imei: "",
      isSelectorShow: false,
      selectorValue: "畅停",
      serviceId: "5",
      serviceData: [],
      dealArray: [],
      isProgress: false,
      checkTimer: [],
    };
  },
  created() {
    this.getServiceList();
  },
  beforeDestroy() {
    if(this.checkTimer.length > 0) {
      this.checkTimer.map(timer => {
        clearTimeout(timer);
      })
      this.checkTimer = [];
    }
  },
  methods: {
    // 存储设置成功的结果到数据库
    saveSettingResult(index, status) {
      let params = {
        imei: this.dealArray[index].imei,
        lockNo: this.dealArray[index].lockNo,
        serviceId: parseInt(this.serviceId),
        statusCode: status  // 只有成功的状态才保存
      }
      axios.post(apiConfig.saveSetting, params).then(res => {
        console.log('数据保存成功')
      })
    },
    // 根据返回的cmdId 检查是否添加成功
    checkSettingSuccess(index, cmdId) {
      this.dealArray[index].status = 0;
      // console.log('checkTimer:', this.checkTimer)
      this.checkTimer[index] = setTimeout(() => {
        let params = {
          CmdId: cmdId,
        };
        axios.post(apiConfig.checkSettingSuccess, params).then((res) => {
          if (res.data.Result.CfgStatus === 0) {
            clearTimeout(this.checkTimer[index]);
            this.checkTimer[index] = null;
            this.checkSettingSuccess(index, cmdId);
          } else {
            // 表示设置成功
            clearTimeout(this.checkTimer[index]);
            this.checkTimer[index] = null;
            this.dealArray[index].status = 1; // 表示设置成功
            this.saveSettingResult(index, 200);
            // 进行ctp park流程的操作
            this.checkParkDeviceIsset(index, this.dealArray[index].oldLockNo ? this.dealArray[index].oldLockNo : this.dealArray[index].lockNo);
            setTimeout(() => {
              if(this.dealArray[index].isset === 1) {
                this.addParkDevice(index, this.dealArray[index].lockNo)
              }else if(this.dealArray[index].isset === 0) {
                this.removeParkDevice(this.dealArray[index].oldLockNo);
                setTimeout(() => {
                  this.addParkDevice(index, this.dealArray[index].lockNo);
                }, 500);
              }
            }, 500);

          }
        }).catch(err => {
            clearTimeout(this.checkTimer[index]);
            this.checkTimer[index] = null;
            this.checkSettingSuccess(index, cmdId);
        });
      }, 3000);
    },
    // 20221028 增加新的功能，主要和ctp park 后台进行交互
    getOldLockNoByIMEI(i, imei) {
      let params = {
        imei: imei,
        statusCode: 200
      }
      axios.get(apiConfig.getSettingHistory, {params: params}).then(({data}) => {
        if(data.code === 200) {
          if(data.data.records.length > 0) {
            this.dealArray[i].oldLockNo = data.data.records[0].lockNo; // 取值最新一条
          }else {
            this.dealArray[i].oldLockNo = '';
          }
        }
      })
    },
    // 通过ctp park的接口查询设备是否存在过
    checkParkDeviceIsset(i, lockNo) {
      let params = new FormData();
      params.append('device_sn', lockNo);
      axios.post(apiConfig.checkParkDeviceIsset, params, {headers: formDataHeader}).then(({data}) => {
        if(data.code === 0) {
          console.log('查询ctp park device成功');
          this.dealArray[i].isset = data.content.isset;  // 1 不存在，0 存在
        }

      })
    },
    // 添加ctp park设备
    addParkDevice(i, lockNo) {
      let params = new FormData();
      params.append('device_sn', lockNo);
      params.append('device_name', lockNo);
      axios.post(apiConfig.addParkDevice, params, {headers: formDataHeader}).then(({data}) => {
        if(data.code === 0) {
          console.log('添加ctp park device成功');
          console.log('add device:', data)
        }else if(data.code === -1) {
          this.dealArray[i].status = 3;
        }
      })
    },
    // 添加ctp park设备
    removeParkDevice(lockNo) {
      let params = new FormData();
      params.append('device_sn', lockNo);
      axios.post(apiConfig.removeParkDevice, params, {headers: formDataHeader}).then(({data}) => {
        if(data.code === 0) {
          console.log('删除ctp park device成功');
          console.log('remove device:', data)
        }
      })
    },
    // add Setting
    addSettingService() {
      let index = 0;
      let addTimer = setInterval(() => {
        if(index < this.dealArray.length) {
          this.addOneService(index);
          index++;
        }else {
          clearInterval(addTimer);
        }
      }, 3000)
    },
    addOneService(i) {
      // 首先执行一下根据imei查询锁号的服务
      this.getOldLockNoByIMEI(i, this.dealArray[i].imei);
      console.log(this.dealArray[i])

      let params = {
          imei: this.dealArray[i].imei,
          lockNo: this.dealArray[i].lockNo,
          serviceId: parseInt(this.serviceId),
        };

        axios.post(apiConfig.addSetting, params).then(res => {
          if (res.data.StatusCode === 200) {
            this.saveSettingResult(i, 0); // 持久化纪录一下开始设置
            let t = setTimeout(()=> {
              this.checkSettingSuccess(i, res.data.Result.CmdId);
            }, 3000 * (i+1));
          }else if(res.data.StatusCode === 1007){ // 表示已经在设备有纪录了，但是还是继续跑
            let t = setTimeout(()=> {
              this.checkSettingSuccess(i, res.data.Result.CmdId);
            }, 3000 * (i + 1));
          }else {
            Toast.info(res.data.Message);
            this.dealArray[i].status = 2;
          }
        })
        .catch(err => {
          Toast.info(err);
          if(err.response) {
            if(err.response.status === 401) {
              Toast.info("请重新登录");
              this.$router.push({path: '/login'});
            }else {
              this.dealArray[i].status = 2;
            }
          }
        })
    },
    // 获取服务列表
    getServiceList() {
      axios.get(apiConfig.getServiceList).then(res => {
        if(res.data) {
          res.data.map(d => {
            this.serviceData.push({
              value: d.Id,
              text: d.Name.split('_')[0]
            })
          })
        }
      })
      .catch(err => {
        if(err.response.status === 401) {
          Toast.info("请重新登录");
          this.$router.push({path: '/login'});
        }
      })
    },
    // 结束当前进程
    finishProgress() {
      // this.isProgress = false;
      this.checkTimer.map(timer => {
        clearTimeout(timer);
        timer = null;
      })
      this.checkTimer = [];
      // 把pending的状态设置为失败
      for(let i = 0; i < this.dealArray.length; i++) {
        if(this.dealArray[i].status === 0) {
          this.dealArray[i].status = 2;
        }
      }
    },
    beginProgress() {
      this.isProgress = true;
      this.addSettingService();
    },
    confirmFinish() {
      Dialog.confirm({
        title: "确认",
        content: "是否要结束当前进程",
        confirmText: "结束",
        onConfirm: () => this.finishProgress(),
      });
    },
    confirmBegin() {
      if (this.dealArray.length > 0) {
        Dialog.confirm({
          title: "确认",
          content: "是否要开始这" + this.dealArray.length + "条设置配置",
          confirmText: "开始",
          onConfirm: () => {
            this.beginProgress();
          },
        });
      } else {
        Toast.info("请添加正确的锁号和IEMI");
      }
    },
    // check 锁号或是iemi的长度
    checkLength(type) {
      if (type === "iemi") {
        if (this.imei.length !== 15) {
          Toast.info("IMEI号为15位数字，请校验并重新输入");
        }
      } else {
        if (this.lockNo.length !== 10) {
          Toast.info("锁号为10位数字，请校验并重新输入");
        }
      }
    },
    // 删除一条列表中的数据
    removeLine(index) {
      this.dealArray.splice(index, 1);
    },
    getScanLockCode(code) {
      this.isErScan = false;
      this.lockNo = this.filterLock(code);
    },
    getScanIMEICode(code) {
      this.isBarScan = false;
      this.imei = code;
    },
    // 从二维码中过滤出锁号
    filterLock(string, params = "lock") {
      if (string) {
        let search = string.split("?")[1];
        return search.replace(params + "=", "");
      }
    },
    clearSuccess() {
      if(this.dealArray.length > 0) {
        for(let i = 0; i < this.dealArray.length; i++) {
          if(this.dealArray[i].status === 1) {
            this.dealArray.splice(i, 1);
          }
        }
      }

    },
    confirmClearAll() {
      Dialog.confirm({
        title: "确认",
        content: "是否要删除所有结果",
        confirmText: "删除",
        onConfirm: () => {
          this.dealArray = [];
        },
      });
    },
    // 确认是否删除
    confirmRemove(index) {
      Dialog.confirm({
        title: "确认",
        content: "是否要删除此条纪录",
        confirmText: "删除",
        onConfirm: () => this.removeLine(index),
      });
    },
    // 查看是否重复
    checkDouble(no, type) {
      let result = 0;
      if(this.dealArray.length > 0) {
        this.dealArray.map(d => {
          if(no.toString() === d[type]) {
            result = 1;
          }
        })
      }
      return result;
    },
    addSetting() {
      this.isProgress = false;
      if (this.dealArray.length > 10) {
        Toast.info("最多添加10条数据批量处理");
        return false;
      }
      // 判断一组锁号和IMEI号是否有重复
      if(this.checkDouble(this.lockNo, 'lockNo') === 1 || this.checkDouble(this.imei, 'imei') === 1) {
        Toast.info('请勿重复提交相同的锁号或是IMEI号');
        return false;
      }
      // 判断锁号和IMEI号的格式
      if (this.lockNo.length === 10 && this.imei.length === 15) {
        this.dealArray.push({
          lockNo: this.lockNo,
          imei: this.imei,
          status: 0
        });
        // 清空输入框
        this.lockNo = "";
        this.imei = "";
      } else {
        Toast.info("请输入正确的锁号或IEMI");
      }

    },
    // 控制config Modal的显示
    controlScanModal(bool, type) {
      if (type === "er") {
        this.isErScan = bool;
      } else if (type === "bar") {
        this.isBarScan = bool;
      }
    },
    onSelectorChoose(option) {
      this.serviceId = option.value;
      this.selectorValue = option.text;
    },
    showSelector() {
      this.isSelectorShow = true;
    },
  },
};
</script>

<style lang="less" src="./setting.less" ></style>
